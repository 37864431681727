module.exports = new Promise(resolve => {
      const hostUrl = window._env_.CORE_UI_REMOTE_URL;
      const remoteUrl = hostUrl + '/host.js';
      const script = document.createElement('script');
      script.src = remoteUrl;
      script.onload = () => {
        const proxy = {
          get: (request) => window.host.get(request),
          init: (arg) => {
            try {
              return window.host.init(arg);
            } catch(e) {
              console.log('remote container already initialized');
            }
          }
        }
        resolve(proxy)
      };
      document.head.appendChild(script);
    });